<template>
  <form @submit.prevent="$emit('submit')">
    <div class="bg-white shadow-sm rounded-md">
      <div class="border-b p-4 xl:px-6 flex items-center justify-between">
        <div class="flex items-center">
          <span class="material-icons text-big-stone">{{ icon }}</span>
          <h2 class="text-lg font-bold ml-1">{{ title }}</h2>
        </div>
        <span class="hidden sm:flex text-sm text-big-stone text-opacity-50">{{ description }}</span>
      </div>
      <div class="relative">
        <div v-if="isLoading" class="absolute inset-0 bg-white flex items-center justify-center z-50">
          <icon-loading class="w-5 h-5 text-blumine" />
        </div>

        <div class="p-4 xl:p-6">
          <slot />
          <div class="mt-6">
            <slot name="add" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="$slots.actions" class="pt-4 text-right">
      <slot name="actions" />
    </div>
  </form>
</template>

<script>
import { IconLoading } from '@/components/icons'

export default {
  name: 'form-layout',

  props: ['title', 'description', 'icon', 'isLoading'],

  emits: ['submit'],

  components: {
    IconLoading,
  },
}
</script>
