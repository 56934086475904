<template>
  <nav-secondary :routes="routes" />
</template>

<script>
import { computed } from 'vue'
import { store, getStoreProfile } from '@/services/store.js'
import NavSecondary from '@/components/NavSecondary.vue'
import { IconMachines, IconUsers, IconDisabledOwners, IconProfile, IconAnnouncements } from '@/components/icons/secondary'

export default {
  components: {
    NavSecondary
  },
  setup() {
    const routes = computed(() => {
      const res = [
        {
          label: 'Machines',
          to: { name: 'SettingsMachinesIndex' },
          desc: 'Manage machine profile',
          icon: IconMachines,
        },
      ]
      if (store.profileData.is_administrator) {
        res.push({
          label: 'Users',
          to: { name: 'SettingsUsersIndex' },
          desc: 'Create and Edit user profiles',
          icon: IconUsers,
        })
        res.push({
          label: 'Disabled Owners',
          to: { name: 'SettingsDisabledOwnersIndex' },
          desc: 'Disable users',
          icon:  IconDisabledOwners,
        })
        res.push({
          label: 'Announcements',
          to: { name: 'SettingsAnnouncementsIndex' },
          desc: 'Manage banners',
          icon:  IconAnnouncements,
        })
      }
      res.push({
        label: 'My Profile',
        to: { name: 'Profile' },
        desc: 'Setup Password and Notifications',
        icon:  IconProfile,
      })
      return res
    })

    getStoreProfile()

    return {
      routes,
    }
  }
}
</script>
