<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-warning v-if="! state.hasAccess" title="Access denied">
      You are not allowed to manage Announcement
    </alert-warning>

    <div v-else>
      <div class="flex flex-row justify-between items-center border-b pb-2">
        <h2 class="font-bold text-lg sm:text-2xl">{{ state.title }}</h2>
        <router-link :to="backRoute" class="group">
          <div class=" flex items-center">
            <svg class="w-4 h-4 text-gray-400 group-hover:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <p class="text-sm text-gray-500 group-hover:text-gray-900">Back</p>
          </div>
        </router-link>
      </div>

      <div class="mt-6 xl:mt-8">
        <form-layout
          @submit="submitForm"
          title="Announcement"
          icon="campaign"
          :is-loading="state.isInitialLoading"
        >
          <div class="space-y-4">
            <errors :errors="state.errors" />
            <select-box label="Message Type" v-model="state.form.message_type" :required="true">
              <option :value="null">Select Message Type</option>
              <option value="INFO">Info</option>
              <option value="WARNING">Warning</option>
              <option value="IMPORTANT">Important</option>
            </select-box>
            <text-input
              type="text"
              label="Message"
              v-model="state.form.message"
              :required="true"
            />
            <text-input
              type="text"
              label="Link"
              v-model="state.form.link"
            />
            <date-time-picker label="Display Since" v-model="state.form.display_since" :required="true" />
            <date-time-picker label="Display Till" v-model="state.form.display_till" :required="true" />
          </div>
          <template #actions>
            <submit-button :is-loading="state.isSaving">Submit</submit-button>
          </template>
        </form-layout>
      </div>
    </div>
  </layout>
</template>

<script>
import { reactive, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import http from '@/services/http.js'
import { store, getStoreProfile } from '@/services/store.js'
import date from '@/helpers/date'

import Layout from '@/layouts/Default.vue'
import FormLayout from '@/components/form/Layout.vue'
import Errors from '@/components/form/Errors.vue'
import TextInput from '@/components/form/Text.vue'
import SelectBox from '@/components/form/Select.vue'
import DateTimePicker from '@/components/form/DateTimePicker'
import AlertWarning from '@/components/alerts/Warning.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'
import SettingsNav from '@/components/settings/Nav'

export default {
  name: 'settings-announcements-create',

  components: {
    Layout,
    AlertWarning,
    FormLayout,
    TextInput,
    DateTimePicker,
    SelectBox,
    SubmitButton,
    Errors,
    SettingsNav
  },

  setup() {
    const router = useRouter()

    const route = useRoute()
    const announcementId = route.params.id

    const backRoute = { name: 'SettingsAnnouncementsIndex' }
    const state = reactive({
      title: 'Add Announcement',
      hasAccess: true,
      errors: [],
      isInitialLoading: true,
      isSaving: false,
      form: {
      },
    })

    const init = () => {
      if (store.profileLoaded) {
        if (!store.profileData.is_administrator) {
          state.hasAccess = false
        }

        if (announcementId) {
          state.title = 'Edit Announcement'
          loadAnnouncement(announcementId)
        }
        else {
          state.isInitialLoading = false
        }
      }
    }

    const loadAnnouncement = async (id) => {
      const { data } = await http.get('announcement')
      const form = data.data.find(a => a.id === id)
      const format = 'YYYY-MM-DD HH:mm:ss'
      form.display_since = date.utc(form.display_since).local().format(format)
      form.display_till = date.utc(form.display_till).local().format(format)
      state.form = form

      state.isInitialLoading = false
    }

    const submitForm = async () => {
      state.errors = []
      state.isSaving = true

      const form = Object.assign({}, state.form)
      if (!form.link || form.link.length === 0) {
        delete form.link
      }

      form.display_since = date.instance(form.display_since).utc().format()
      form.display_till = date.instance(form.display_till).utc().format()

      try {
        await http.post(`/announcement`, form)
        router.push(backRoute)
      } catch (e) {
        if (e.response) {
          state.errors.push(e.response.data.Message)
        } else {
          state.errors.push('Something went wrong. Please, try again.')
        }
      }

      state.isSaving = false
    }

    getStoreProfile()
    
    init()
    watch(
      () => store.profileData.is_administrator,
      () => {
        init()
      }
    )

    return {
      state,
      submitForm,
      backRoute,
    }
  }
}
</script>
